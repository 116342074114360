/* global BAM */

import {sendGTMSubscriptionEvent} from './../libs/trackingHelper'

/**
 * Search an elements parents, with an optional selector.
 *
 * @param {object} element DOT element to search parents.
 * @param {string} parentSelector Parent selector.
 * @return {array} Found parent DOM elements.
 */
export function findParents( element, parentSelector = '' ) {
  const elements = []

  const ishaveselector = parentSelector !== ''

  element = element.parentElement
  while ( element !== null ) {
    if ( element.nodeType !== Node.ELEMENT_NODE ) {
      element = element.parentElement
      continue
    }

    if ( !ishaveselector || element.matches( parentSelector ) ) {
      elements.push( element )
    }

    element = element.parentElement
  }

  return elements
}

/**
 * Add listing helper functions.
 * @param {object} $ The jQuery instance.
 */
export function initLising( $ ) {
  $.extend({
    initLoading: function initLoading( obj ) {
      if ( typeof obj === 'undefined' ) {
        return false
      }

      if ( typeof obj === 'string' ) {
        obj = $( obj )
      }

      obj.addClass( 'loading' )
    },
    stopLoading: function stopLoading( obj ) {
      if ( typeof obj === 'undefined' ) {
        return false
      }

      if ( typeof obj === 'string' ) {
        obj = $( obj )
      }

      obj.removeClass( 'loading' )
    },
    initReorder: function initReorder( obj ) {
      if ( typeof obj === 'undefined' ) {
        return
      }

      if ( typeof obj === 'string' ) {
        obj = $( obj )
      }

      const form = $( `#${ obj.attr( 'data-formid' )}` )
      form.attr( 'data-pagerclick', 0 )

      const orderBtns = obj.find( '.order' )
      orderBtns.unbind( 'click' )
      orderBtns.click( function orderClick( e ) {
        e.preventDefault()
        const key = $( this ).attr( 'data-metakey' )
        const otype = $( this ).attr( 'data-ordertype' )

        if ( !form ) {
          return
        }

        form.attr( 'data-order', key )
          .attr( 'data-ordertype', otype )
          .attr( 'data-orderby', '' )

        if ( 'title' === key ) {
          form.attr( 'data-order','' )
            .attr( 'data-orderby', 'title' )
        }

        form.find( 'input[type=submit]' ).click()
      })
    },
    initPager: function initPager( obj ) {
      if ( typeof obj === 'undefined' ) {
        return false
      }

      if ( typeof obj === 'string' ) {
        obj = $( obj )
      }

      const form = $( `#${ obj.attr( 'data-formid' )}` )
      form.attr( 'data-pagerclick', 0 )

      const pager = obj.find( '.wp-pagenavi a' )
      pager.unbind( 'click' )
      pager.click( function pagerClick( e ) {
        e.preventDefault()
        const page = $.trim( $( this ).attr( 'href' ).replace( `${BAM.ajax_url }?paged=`, '' ) )
        if ( form ) {
          form.attr( 'data-page', page ).attr( 'data-pagerclick', 1 )
          form.find( 'input[type=submit]' ).click()
        }

        return false
      })
    },
    initSubscribe: function initSubscribe() {
      $( 'a.subscribe' ).unbind( 'click' )
      $( 'a.subscribe' ).click( function subscribeClick( e ) {
        const cpName = $( this ).closest( '.list__item' ).find( '>div:nth-child(2) *:not(label)' ).text()
        e.preventDefault()
        $.ajax({
          type: 'POST',
          url: BAM.ajax_url,
          dataType: 'JSON',
          data: { action: 'cusip_subscribe', nonce: BAM.nonce, id: $( this ).attr( 'data-id' ) },
          success: function onSuccess( response ) {
            if ( response.success && response.data.subscribed ) {
              $( `a[data-id="${ response.data.subscribed }"]` ).removeClass( 'subscribe' ).addClass( 'unsubscribe' )
              sendGTMSubscriptionEvent( cpName )
              $.initSubscribe()
            }
          },
        })

        return false
      })

      $( 'a.unsubscribe' ).unbind( 'click' )
      $( 'a.unsubscribe' ).click( function unsubscribeClick( e ) {
        e.preventDefault()
        $.ajax({
          type: 'POST',
          url: BAM.ajax_url,
          dataType: 'JSON',
          data: { action: 'cusip_unsubscribe', nonce: BAM.nonce, id: $( this ).attr( 'data-id' ) },
          success: function onSuccess( response ) {
            if ( response.success && response.data.subscribed ) {
              const $elem = $( `a[data-id="${ response.data.subscribed }"]` )
              if ( $elem.attr( 'data-deleteafter' ) === 'true' ) {
                $elem.parents( '.list__item' ).remove()
              } else {
                $elem.removeClass( 'unsubscribe' ).addClass( 'subscribe' )
                $.initSubscribe()
              }
            }
          }
        })

        return false
      })
    }
  })

  $.initSubscribe()
}

/**
 * Extend $ with helper methods.
 * @param {object} $ The jQuery instance.
 */
export function extendFn( $ ) {
  $.fn.extend({
    toggleText: function toggleText( a, b ) {
      this.html( this.html() === b ? a : b )
      return this
    },
    scrollTo: function scrollTo( $diff ) {
      $diff = ( $diff ) ? $diff : 0
      $( 'html, body' ).stop().animate({scrollTop: this.offset().top - $diff}, 1000 )
    }
  })
}
