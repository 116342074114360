import {extendFn} from './functions'

jQuery.noConflict()
jQuery( document ).ready( function docReady( $ ) {
  extendFn( $ )

  $( '.pdf-show-more' ).click( function showMoreClick( e ) {
    e.preventDefault()
    $( this ).parent().toggleClass( 'open' ).parents( '.financial-box__list' ).find( '.toggle-item' ).toggle()
    return false
  })
})
