window.dataLayer = window.dataLayer || []

/**
 * Define gtag function.
 */
function gtag() {
  window.dataLayer.push( arguments )
}

/**
 * Register a search event on GA using gtag function.
 *
 * @param {object} searchDataFilters Search filter data to create gtag event parameters.
 */
export function sendGTMSearchEvent( searchDataFilters ) {
  if ( !Array.isArray( searchDataFilters ) || !gtag ) {
    return
  }

  const activeTab = document.querySelector( '.search-tabs .active' )
  const tabName = activeTab.dataset.tab ? activeTab.dataset.tab : ''
  const tabCats = {
    blue_tab: 'BAM Credit Profiles',
    red_tab: 'Secondary Market Capacity',
    green_tab: 'GreenStar Bonds',
  }
  const eventParams = {
    event_category: tabCats[tabName] ? tabCats[tabName] : 'BAM Credit Profiles',
  }

  for ( const filter of searchDataFilters ) {
    if ( '' === filter.value ) {
      continue
    }

    if ( 'keyword' === filter.name ) {
      eventParams.event_label = filter.value
    }
  }

  gtag( 'event', 'Credit Profiles Search', eventParams )
}

/**
 * Register a CP subscription event on GA using gtag function.
 *
 * @param {String} subscriptionName CP name being subscribed to.
 */
export function sendGTMSubscriptionEvent( subscriptionName ) {
  if ( !subscriptionName || !gtag ) {
    return
  }

  const eventParams = {
    event_category: 'Subscription',
    event_label: `CP: ${subscriptionName}`,
    value: 1,
  }

  gtag( 'event', 'BAM Credit Profiles', eventParams )
}

/**
 * Register a credit profile downlaod event on GA using gtag function.
 *
 * @param {object} downloadData Credit profile data to create gtag event parameters.
 */
export function sendGTMPDFDowloadEvent( downloadData ) {
  if ( !downloadData ) {
    return
  }

  if ( gtag ) {
    const eventParams = {
      event_category: downloadData.category,
      event_label: downloadData.file,
    }

    gtag( 'event', 'PDF download', eventParams )
  }
}
